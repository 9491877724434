var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CustomModal',{attrs:{"name":_vm.modalName,"isScrollable":"","classes":"tableSettingsModal","width":"90%"},on:{"before-close":_vm.reset,"before-open":_vm.reset},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Title',{staticClass:"tableSettingsModal__title"},[_vm._v(" Configure the table ")])]},proxy:true}])},[_c('div',{staticClass:"tableSettingsModal__close"},[_c('Icon',{attrs:{"name":"close","is-clickable":"","color":"secondary-500"},on:{"click":_vm.close}})],1),_c('Form',{staticClass:"tableSettingsModal__form",scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('Draggable',{staticClass:"mb-48",attrs:{"handle":".drag-handler"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},_vm._l((_vm.innerValue),function(column){return _c('div',{key:column.id,staticClass:"tableSettingsModal__form__row"},[_c('div',{staticClass:"tableSettingsModal__form__row__block"},[_c('div',{staticClass:"drag-handler"},[_c('Icon',{attrs:{"name":"drag-handler","color":"secondary-400"}})],1),(!_vm.editLabelIsActiveMap[column.id])?_c('span',{class:[
              'tableSettingsModal__form__row__name',
              {
                'm-hidden': column.isHidden,
              },
            ]},[_vm._v(" "+_vm._s(_vm.editLabelMap[column.id])+" ")]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editLabelMap[column.id]),expression:"editLabelMap[column.id]"}],ref:(el) => (_vm.editLabelRefsMap[column.id] = el),refInFor:true,class:[
              'tableSettingsModal__form__row__name',
              'm-editable',
              {
                'm-hidden': column.isHidden,
              },
            ],domProps:{"value":(_vm.editLabelMap[column.id])},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSaveLabel(column)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editLabelMap, column.id, $event.target.value)}}}),(!_vm.editLabelIsActiveMap[column.id])?_c('span',{staticClass:"tableSettingsModal__form__row__id"},[_vm._v(" ["+_vm._s(column.id)+"] ")]):_vm._e()]),_c('div',{staticClass:"tableSettingsModal__form__row__block"},[_c('Icon',{attrs:{"name":_vm.editLabelIsActiveMap[column.id] ? 'checkmark-circle' : 'pencil',"color":"secondary-500","is-clickable":""},on:{"click":function($event){return _vm.handleEditClick(column)}}}),_c('Icon',{attrs:{"name":column.isHidden ? 'eye-closed' : 'eye',"color":_vm.editLabelIsActiveMap[column.id]
                ? 'secondary-400'
                : 'secondary-500',"is-clickable":!_vm.editLabelIsActiveMap[column.id]},on:{"click":function($event){return _vm.handleVisibilityClick(column)}}})],1)])}),0),_c('div',{staticClass:"tableSettingsModal__form__actions"},[_c('Button',{attrs:{"is-outlined":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('Button',{attrs:{"is-loading":_vm.isSubmitting},on:{"click":_vm.handleSubmit}},[_vm._v(" Save ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }