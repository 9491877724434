<template>
  <div>
    <vue-good-table
      :columns="tableColumns"
      :rows="rows"
      class="default-table"
      styleClass="table"
      @on-row-click="$emit('on-row-click', $event.row.id)"
    >
      <template #table-row="props">
        <div
          v-if="props.column.field === 'settings' && getMenuOptions().length"
          class="table__settings"
        >
          <div
            v-for="(item, index) in getMenuOptions()"
            :key="index"
            v-tooltip="item.name"
          >
            <Icon
              :name="item.icon"
              is-clickable
              :size="24"
              :color="item.iconColor || 'secondary-400'"
              @click="handleMenuSelect(item, props.row)"
            />
          </div>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #table-actions-bottom>
        <slot name="footer" />
      </template>
      <template slot="table-column" slot-scope="props">
        <Icon
          v-if="props.column.field === 'settings'"
          name="gear"
          is-clickable
          :size="24"
          color="secondary-500"
          @click="$modal.show(modalName)"
        />
        <span v-else>
          {{ props.column?.label }}
        </span>
      </template></vue-good-table
    >
    <TableSettings
      :modalName="modalName"
      :value="this.innerColumns"
      :is-submitting="isTableSettingsSubmitting"
      @submit="saveTableSettings"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TableSettings from "@/components/table/TableSettings.vue";
import {
  BOOKING_MENU_OPTION_VALUES,
  // DEFAULT_BOOKINGS_TABLE_COLUMNS,
} from "@/helpers/mocks";
import { getColumns } from "@/helpers/table";

export default {
  name: "Table",
  components: { TableSettings },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
    },
    hasSettings: {
      type: Boolean,
      default: false,
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      venueUserJunction: (state) => state.users.venueUserJunction,
      bookings: (state) => state.bookings.list,
      detailedBookings: (state) => state.bookings.detailedList,
      detailedListLength: (state) => state.bookings.detailedListLength,
      activeFilter: (state) => state.bookings.activeFilter,
      selectedProduct: (state) => state.products.selectedProduct,
      products: (state) => state.products.list,
      dateRangeFilter: (state) => state.bookings.dateRangeFilter,
      periodFilter: (state) => state.bookings.periodFilter,
      selectedView: (state) => state.bookings.selectedView,
      activeSortField: (state) => state.bookings.activeSortField,
    }),
    innerColumns() {
      const isNew =
        this.venueUserJunction?.[`${this.name}Columns`]?.length !==
        this.columns?.length;

      return isNew
        ? this.columns
        : this.venueUserJunction?.[`${this.name}Columns`] || this.columns;
    },
    tableColumns() {
      return [
        ...getColumns(
          this.venueUserJunction?.[`${this.name}Columns`],
          this.columns
        ),
        this.hasSettings && {
          field: "settings",
          sortable: false,
        },
      ]
        .filter(Boolean)
        .map((column) => this.getColumnSettings(column));
    },
  },
  methods: {
    ...mapActions({
      updateVenueUserJunction: "users/updateVenueUserJunction",
    }),
    async saveTableSettings(data) {
      try {
        this.isTableSettingsSubmitting = true;
        await this.updateVenueUserJunction({ [`${this.name}Columns`]: data });
        this.$modal.hide(this.modalName);
      } finally {
        this.isTableSettingsSubmitting = false;
      }
    },
    getMenuOptions() {
      return [
        {
          value: BOOKING_MENU_OPTION_VALUES.OPEN,
          name: "Open details",
          icon: "eye",
        },
        ...this.menuOptions,
      ].filter(Boolean);
    },
    async handleMenuSelect(option, row) {
      if (option.value === BOOKING_MENU_OPTION_VALUES.OPEN) {
        this.$emit("on-view-click", row?.id);
      } else {
        this.$emit("menu-select", { option, row });
      }
    },
    getColumnSettings(column) {
      return {
        ...column,
        thClass: [
          "table__head",
          column.sortable &&
            column.field === this.activeSortField &&
            `m-${this.activeSortDirection}`,
          column.field === "settings" && "m-fixed-right",
        ].join(" "),
        tdClass: [
          "table__cell",
          column.field === "settings" && "m-fixed-right",
        ].join(" "),
        sortable: false,
      };
    },
  },
  data() {
    return {
      modalName: `${this.name}SettingsModal`,
      isTableSettingsSubmitting: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/table.scss";

.table {
  tr {
    cursor: initial !important;
  }
  &__dropdown-wrapper {
    max-width: 100px;
  }

  &__head {
    background-color: $secondary-300;
    &.m-fixed-right {
      position: sticky !important;
      top: 0;
      right: 0;
    }
  }

  &__cell {
    background-color: $white;
    &.m-fixed-right {
      position: sticky;
      top: 0;
      right: 0;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
</style>
