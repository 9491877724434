export const getColumns = (columns, defaultColumns) => {
  return columns
    ? [
        ...columns
          .filter((columnConfig) => !columnConfig.isHidden)
          .map((columnConfig) => {
            const columnDefinition = defaultColumns.find(
              (item) => item.id === columnConfig.id
            );
            const label = columnConfig?.label || columnDefinition?.label;
            return { ...columnDefinition, label };
          }),
        ...defaultColumns.filter(
          (defaultColumn) =>
            !columns.some((column) => defaultColumn.id === column.id)
        ),
      ]
    : defaultColumns;
};
